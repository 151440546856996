import { Networking } from "@actions/index";
import { Reducer } from "redux";
import { BaseState, BaseAction } from "@interface/common";

type Days = string[];

const InitialState: BaseState<Days> = {
  data: [],
};

const reducer: Reducer<BaseState<Days>, BaseAction<Days>> = (
  state = InitialState,
  action
) => {
  switch (action.type) {
    case Networking.Days.SET_DATA:
      return {
        ...state,
        data: [...state.data, ...(action.data ? action.data : [])],
      };
    default:
      return state;
  }
};

export default reducer;
