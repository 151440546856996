import { combineReducers } from "redux";

import Company from "./Company";
import Days from "./Days";
import Employee from "./Employee";
import Exponent from "./Exponent";
import Member from "./Member";
import Search from "./Search";
import User from "./User";

export default combineReducers({
  Company,
  Days,
  Employee,
  Exponent,
  Member,
  Search,
  User,
});
