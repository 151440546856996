import { Workshop as WorkshopType } from "@interface/entities/workshop";
import { BaseAction } from "@interface/common";

export default class Workshop {
  public static readonly SET_DATA = "WORKSHOP_SET_DATA";

  public static setData(data: WorkshopType): BaseAction<Workshop> {
    return {
      type: Workshop.SET_DATA,
      data,
    };
  }
}
