import React, { ReactElement } from "react";
import { Provider } from "react-redux";
import { Store } from "@helpers/Store";

import "@helpers/TimeIncrement";
import "@public/sass/index.sass";
import Preloader from "@components/preloader/Preloader";

const Ping = React.lazy(() => import(/* webpackChunkName: 'ContainerPing' */ "./Ping"));
const Router = React.lazy(() => import(/* webpackChunkName: 'ContainerAlert' */ "./Router"));
const Modal = React.lazy(() => import(/* webpackChunkName: 'ContainerModal' */ "./Modal"));
const Alert = React.lazy(() => import(/* webpackChunkName: 'ContainerAlert' */ "./Alert"));

const App = (): ReactElement => (
  <Provider store={Store}>
    <React.Suspense fallback={<Preloader />}>
      <Ping>
        <React.Suspense fallback={<Preloader />}>
          <Router />

          <React.Suspense fallback={<Preloader />}>
            <Modal />

            <React.Suspense fallback={<Preloader />}>
              <Alert />
            </React.Suspense>
          </React.Suspense>
        </React.Suspense>
      </Ping>
    </React.Suspense>
  </Provider>
);

export default App;
