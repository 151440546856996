import { WorkshopNews as WorkshopNewsType } from "@interface/entities/workshopNews";
import { BaseAction } from "@interface/common";

export default class WorkshopNews {
  public static readonly SET_DATA = "WORKSHOP_NEWS_SET_DATA";

  public static setData(data: WorkshopNewsType): BaseAction<WorkshopNews> {
    return {
      type: WorkshopNews.SET_DATA,
      data,
    };
  }
}
