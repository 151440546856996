import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Preloader = (): ReactElement => {
  return (
    <div className="cssload-container">
      <div className="cssload-loading"><i></i><i></i></div>
    </div>
  );
};

export default Preloader;
