import { NetworkingStats as NetworkingStatsType } from "@interface/entities/networkingStats";
import { BaseAction } from "@interface/common";

export default class NetworkingStats {
  public static readonly SET_DATA = "NETWORKING_STATS_SET_DATA";

  public static setData(data: NetworkingStatsType): BaseAction<NetworkingStats> {
    return {
      type: NetworkingStats.SET_DATA,
      data,
    };
  }
}
