import { combineReducers } from "redux";

import Dynamic from "./Dynamic";
import NetworkingStats from "./NetworkingStats";
import Pingpong from "./Pingpong";
import Rating from "./Rating";
import Time from "./Time";
import User from "./User";
import Workshop from "./Workshop";
import WorkshopNews from "./WorkshopNews";

export default combineReducers({
  Dynamic,
  NetworkingStats,
  Pingpong,
  Rating,
  Time,
  User,
  Workshop,
  WorkshopNews,
});
