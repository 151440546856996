import { FavoriteList } from "@interface/entities/favorites";
import { BaseAction } from "@interface/common";

export default class Favorites {
  public static readonly SET_DATA = "FAVORITES_SET_DATA";

  public static setData(data: FavoriteList): BaseAction<Favorites> {
    return {
      data,
      type: Favorites.SET_DATA,
    };
  }
}
