import { Main } from "@actions/index";
import { Reducer } from "redux";
import { Workshop } from "@interface/entities/workshop";
import { BaseState, BaseAction } from "@interface/common";

const InitialState: BaseState<Workshop> = {
  data: {
    endAt: null,
    registerEndAt: null,
    registerStartAt: null,
    startAt: null,
    title: "",
  },
};

const reducer: Reducer<BaseState<Workshop>, BaseAction<Workshop>> = (
  state = InitialState,
  action
) => {
  switch (action.type) {
    case Main.Workshop.SET_DATA:
      return { ...state, data: { ...state.data, ...action.data } };
    default:
      return state;
  }
};

export default reducer;
