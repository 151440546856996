import * as Action from "@actions/index";
import { Reducer } from "redux";
import { FavoriteList } from "@interface/entities/favorites";
import { BaseState, BaseAction } from "@interface/common";

const InitialState = {
  data: [],
};

const reducer: Reducer<BaseState<FavoriteList[]>, BaseAction<FavoriteList[]>> = (
  state = InitialState,
  action
) => {
  switch (action.type) {
    case Action.Favorites.SET_DATA:
      return {
        ...state,
        data: [...(action.data ? action.data : [])],
      };
    default:
      return state;
  }
};

export default reducer;
